<template>
  <div>
    <el-image
      v-for="(url,index) in imageUrlList"
      :key="index"
      style="width: 100px; height: 100px"
      :src="url+'?x-oss-process=image/resize,l_150'"
      :preview-src-list="imageUrlList">
    </el-image>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading: false,
        imageList: [],
        imageUrlList: [],
      };
    },
    mounted() {
      let jxId = this.$route.query.jxId;
      this.getList(jxId);
    },
    methods: {
      getList(jxId) {
        this.$api.getList(window.config.integrationUrl + "videoHistories/jx/" + jxId).then(response => {
          this.loading = false;
          this.imageList = response.data;
          this.imageUrlList = this.imageList.map(item => {
            return item.fileUrl;
          });
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
